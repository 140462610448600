<template>
  <base-modal :title="$t('enabletfa')" @close="$ionic.modalController.dismiss()">
    <ion-content>
      <ion-item v-if="qr_code">
        <img :src="qr_code" class="w-2/3 mx-auto" />
      </ion-item>
      <ion-text class="text-center m-2"><h5 class="text-2xl">{{ secret }}</h5></ion-text>
      <ion-item>
        <ion-text class="m-2" color="moto"><p v-html="$t('enabletfainstructions')" /></ion-text>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('authenticatorcode') }}</ion-label>
        <ion-input
          type="text"
          class="text-3xl"
          max="6"
          autocomplete="off"
          :placeholder="$t('authenticatorcode')"
          @ionInput="tfa_code = $event.target.value" />
      </ion-item>
      <!-- The following line is only here to prevent chrome from auto-prefilling our authenticator-code with the username -->
      <input type="text" autocomplete="username" style="display: none !important;" />
      <ion-item>
        <ion-label position="stacked">{{ $t('password') }}</ion-label>
        <ion-input
          type="password"
          autocomplete="off"
          :placeholder="$t('password')"
          @ionInput="password = $event.target.value" />
      </ion-item>
    </ion-content>

    <ion-footer>
      <div class="mx-4 my-4">
        <Error :error="error" />
      </div>
      <ion-button :disabled="loading" expand="full" @click="enableTFA" color="moto" class="my-2">
        <ion-spinner color="primary" v-if="loading" :name="loadingSpinner" />
        {{ $t('enabletfa') }}
      </ion-button>
      <ion-button expand="full" @click="$ionic.modalController.dismiss()">
        <ion-label>{{ $t('cancel') }}</ion-label>
      </ion-button>
    </ion-footer>
  </base-modal>
</template>

<style scoped>
  img {
    max-width: 16em;
  }

  ion-spinner {
    transform: scale(0.6);
  }
</style>
<script>
  import BaseModal from './BaseModal.vue';
  import { showToast, TOAST_SUCCESS } from "@/utils/helpers";
  import { ENABLE_TFA_ACTION, GET_TFA_SECRETS_ACTION, GET_USER_BY_TOKEN_ACTION } from "@/store/store-actions";
  import { loadingSpinner } from '@/configs';

  export default {
    props: ['email', 'confirmStep'],
    components: {
      BaseModal,
    },
    data() {
      return {
        loadingSpinner,
        loading: false,
        error: null,
        tfa_code: null,
        password: null,
        qr_code: null,
        secret: null
      }
    },
    async mounted() {
      const tfaSecrets = await this.$store.dispatch(GET_TFA_SECRETS_ACTION)
      this.qr_code = tfaSecrets.qr_code;
      this.secret = tfaSecrets.secret;
    },
    methods: {
      showToast,
      async enableTFA() {
        this.loading = true;
        this.error = null;
        try {
          await this.$store.dispatch(ENABLE_TFA_ACTION, {tfa_code: this.tfa_code, password: this.password});
        } catch (e) {
          this.error = e;
          return;
        } finally {
          this.loading = false;
        }

        await this.showToast(this.$t('tfaisenabled'), TOAST_SUCCESS);

        await this.$store.dispatch(GET_USER_BY_TOKEN_ACTION, {fresh: true});
        await this.$ionic.modalController.dismiss()
      }
    }
  }
</script>