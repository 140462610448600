<template>
  <base-modal :title="$t('disabletfa')" @close="$ionic.modalController.dismiss()">
    <ion-content>
      <ion-item>
        <ion-text class="m-2" color="moto"><p v-html="$t('disabletfawithpasswordinstructions')" /></ion-text>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('password') }}</ion-label>
        <ion-input
          type="password"
          autocomplete="off"
          :placeholder="$t('password')"
          @ionInput="password = $event.target.value" />
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('authenticatorcode') }}</ion-label>
        <ion-input
          type="text"
          autocomplete="off"
          class="text-3xl"
          max="6"
          :placeholder="$t('authenticatorcode')"
          @ionInput="tfa_code = $event.target.value" />
      </ion-item>
    </ion-content>

    <ion-footer>
      <div class="mx-4 my-4">
        <Error :error="error" />
      </div>
      <ion-button :disabled="loading" expand="full" @click="disableTFA" color="moto" class="my-2">
        <ion-spinner color="primary" v-if="loading" :name="loadingSpinner" />
        {{ $t('disabletfa') }}
      </ion-button>
      <ion-button expand="full" @click="showDisableTFAWithEmailModal">
        <ion-label>{{ $t('lostauthenticator') }}</ion-label>
      </ion-button>
      <ion-button expand="full" @click="$ionic.modalController.dismiss()">
        <ion-label>{{ $t('cancel') }}</ion-label>
      </ion-button>
    </ion-footer>
  </base-modal>
</template>

<style scoped>
  ion-spinner {
    transform: scale(0.6);
  }
</style>
<script>
  import BaseModal from './BaseModal.vue';
  import { showToast, TOAST_SUCCESS } from "@/utils/helpers";
  import { DISABLE_TFA_WITH_PASSWORD_ACTION, GET_USER_BY_TOKEN_ACTION } from "@/store/store-actions";
  import DisableTFAWithEmailModal from "@/components/modals/DisableTFAWithEmailModal.vue";
  import { mapGetters } from "vuex";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";
  import { loadingSpinner } from "@/configs";

  export default {
    props: [],
    components: {
      BaseModal,
    },
    data() {
      return {
        loadingSpinner,
        loading: false,
        error: null,
        tfa_code: null,
        password: null,
      }
    },
    computed: {
      ...mapGetters({user: CURRENT_USER_GETTER})
    },
    methods: {
      showToast,
      async disableTFA() {
        this.loading = true;
        this.error = null;
        try {
          await this.$store.dispatch(DISABLE_TFA_WITH_PASSWORD_ACTION, {tfa_code: this.tfa_code, password: this.password});
        } catch (e) {
          this.error = e;
          return;
        } finally {
          this.loading = false;
        }

        await this.showToast(this.$t('tfaisdisabled'), TOAST_SUCCESS);

        await this.$store.dispatch(GET_USER_BY_TOKEN_ACTION, {fresh: true});
        await this.$ionic.modalController.dismiss()
      },
      async showDisableTFAWithEmailModal() {
        const modal = await this.$ionic.modalController.create({
          component: DisableTFAWithEmailModal,
          componentProps: {
            parent: this,
            propsData: {
              email: this.user.email,
            }
          },
        });

        modal.onDidDismiss().then( () => {
          this.$ionic.modalController.dismiss();
        });

        return modal.present();
      },
    }
  }
</script>