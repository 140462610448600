<template>
  <ion-page class="ion-page">
    <GoBackHeader defaultHref="/profile" />

    <ContentContainer>
      <ion-item>
        <ion-text>
          <h1>{{ $t('changeinformation') }}</h1>
        </ion-text>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('twofactorauthentication') }}</ion-label>
        <template v-if="user.has_tfa">
          <ion-text color="moto">{{ $t('tfaisenabledinformation') }}</ion-text>
          <ion-button @click="showDisableTFAModal()">{{ $t('disabletfa') }}</ion-button>
        </template>
        <template v-else>
          <ion-text color="moto">{{ $t('tfaisdisabledinformation') }}</ion-text>
          <ion-button @click="showEnableTFAModal()">{{ $t('enabletfa') }}</ion-button>
        </template>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ $t('email') }}</ion-label>
        <ion-input readonly type="email" :value="user.email" />
        <ion-button @click="changeEmail()">{{ $t('changeemailaddressaction') }}</ion-button>
      </ion-item>
      <ion-list v-if="pendingEmailChanges && pendingEmailChanges.length">
        <ion-list-header>{{ $t('pendingemailchangerequests') }}:</ion-list-header>
        <ion-item v-for="pendingEmailChange in pendingEmailChanges" :key="pendingEmailChange.id">
          <ion-label position="stacked">{{ $t('email') }}:</ion-label>
          <ion-text color="moto">{{ pendingEmailChange.email }}</ion-text>
          <ion-button
            slot="end"
            @click="changeEmail(pendingEmailChange.email, true)">{{ $t('enteronetimepasswordaction') }}
          </ion-button>
          <ion-button slot="end" @click="deletePendingEmailChange(pendingEmailChange.email)">
            <ion-icon icon="trash">{{ $t('enteronetimepasswordaction') }}</ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>
      <InformationPresentation
        v-if="userCopy"
        :model="userCopy"
        :presentation="userPresentation"
        @inputChanged="inputChanged($event)"
        @dateChanged="dateChanged($event)"
        @nameChanged="nameChanged($event)"
        @phoneChanged="phoneChanged($event)"
      />
      <Error :error="error" class="mb-2 "/>
      <ion-button class="ml-2 mb-8" expand="block" color="moto" :disabled="!hasChanges || loading" @click="saveChanges">{{ $t('savechanges') }}</ion-button>
    </ContentContainer>
  </ion-page>
</template>

<script>

  import { mapGetters } from "vuex";
  import { CURRENT_USER_GETTER, PENDING_EMAIL_CHANGES_GETTER } from "@/store/store-getters";
  import ChangeEmailAddressModal from "@/components/modals/ChangeEmailAddressModal";
  import {
    DELETE_EMAIL_CHANGE_ACTION,
    FETCH_PENDING_EMAIL_CHANGES_ACTION, GET_USER_BY_TOKEN_ACTION,
    UPDATE_USER_ACTION
  } from "@/store/store-actions";
  import InformationPresentation from "@/views/profile/InformationPresentation";
  import { userFormPresentation } from "@/components/inputs/field_mapping";
  import * as moment from "moment";
  import { showToast, TOAST_SUCCESS } from "@/utils/helpers";
  import { defaultsDeep } from "lodash";
  import EnableTFAModal from "@/components/modals/EnableTFAModal.vue";
  import DisableTFAWithPasswordModal from "@/components/modals/DisableTFAWithPasswordModal.vue";

  export default {
    components: {InformationPresentation},
    data() {
      return {
        userPresentation: userFormPresentation,
        newEmail: null,
        userCopy: null,
        userChanges: {},
        loading: true,
        error: null,
      }
    },
    computed: {
      ...mapGetters({user: CURRENT_USER_GETTER, pendingEmailChanges: PENDING_EMAIL_CHANGES_GETTER}),
      hasChanges() {
        return !!Object.keys(this.userChanges).length;
      }
    },
    watch: {
      user() {
        this.$nextTick(() => this.cloneUser());
      }
    },
    async mounted() {
      await this.$store.dispatch(FETCH_PENDING_EMAIL_CHANGES_ACTION);
      this.cloneUser();
      this.loading = false;
    },
    methods: {
      showToast,
      cloneUser() {
        if (this.user) {
          this.userCopy = defaultsDeep({}, this.user);
        }
      },
      async showEnableTFAModal() {
        const modal = await this.$ionic.modalController.create({
          component: EnableTFAModal,
          componentProps: {
            parent: this,
          },
        });

        return modal.present();
      },
      async showDisableTFAModal() {
        const modal = await this.$ionic.modalController.create({
          component: DisableTFAWithPasswordModal,
          componentProps: {
            parent: this,
          },
        });

        return modal.present();
      },
      async changeEmail(email, confirmStep) {

        const modal = await this.$ionic.modalController.create({
          component: ChangeEmailAddressModal,
          componentProps: {
            parent: this,
            propsData: {
              email,
              confirmStep
            }
          },
        });

        modal.present();
      },
      async deletePendingEmailChange(email) {
        await this.$store.dispatch(DELETE_EMAIL_CHANGE_ACTION, {email});
        await this.$store.dispatch(FETCH_PENDING_EMAIL_CHANGES_ACTION);
      },
      userKey(formKey) {
        return this.userPresentation[formKey]?.fields[0]?.userKey || formKey;
      },
      changeValue(key, value) {
        this.$set(this.userChanges, key, value);
        this.$set(this.userCopy, key, value);
      },
      inputChanged({value, key}) {
        this.changeValue(this.userKey(key), value);
      },
      dateChanged({value, key}) {
        this.changeValue(this.userKey(key), moment(value).format('YYYY-MM-DD'));
      },
      phoneChanged({value, key}) {
        this.changeValue(`${this.userKey(key)}_tel`, value);
      },
      nameChanged({value, key}) {
        this.changeValue(`${this.userKey(key)}_name`, value);
      },
      clearUserChanges() {
        this.userChanges = {};
      },
      async saveChanges() {
        this.error = null;

        if (!this.hasChanges) {
          return;
        }

        const data = Object.keys(this.userChanges).reduce( (carry, key) => {

          switch (key) {
            case 'sports':
            case 'federations':
            case 'bikes':
              carry[key] = this.userChanges[key].map( (related) => {
                return {
                  id: related.id,
                  pivot: related.pivot
                }
              });
              break;
            default:
              carry[key] = this.userChanges[key];
              break;
          }

          return carry;
        }, {});

        this.loading = true;

        try {
          await this.$store.dispatch(UPDATE_USER_ACTION, {data});
          await this.showToast(this.$t('profilesaved'), TOAST_SUCCESS, 3000, {showCloseButton: true});

          this.clearUserChanges();
          await this.$store.dispatch(GET_USER_BY_TOKEN_ACTION);
          await this.$router.replace({name: this.$routeNames.PROFILE})
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      }
    },
  }
</script>