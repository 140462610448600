<template>
  <base-modal :title="$t('changeemailaddress')" @close="$ionic.modalController.dismiss()">
    <ion-content>
      <template v-if="!onConfirmStep">
        <ion-item key="unconfirmed">
          <ion-label position="stacked">{{ $t('newemailaddress') }}</ion-label>
          <ion-input
            key="email"
            type="email"
            :value="newEmail"
            :placeholder="$t('newemailaddress')"
            @ionInput="newEmail = $event.target.value" />
        </ion-item>
        <ion-item>
          <LoadingButton :loading="loading" :disabled="loading" color="moto" @click="requestEmailChange">
            {{ $t('submit') }}
          </LoadingButton>
        </ion-item>
      </template>
      <template v-else>
        <ion-item class="mb-4">
          <ion-label position="stacked">{{ $t('newemailaddress') }}</ion-label>
          <ion-text>{{ newEmail }}</ion-text>
        </ion-item>
        <ion-item>
          <ion-text color="moto">{{ $t('verificationemailreceived') }}</ion-text>
        </ion-item>
        <ion-item>
          <ion-label position="stacked">{{ $t('onetimepassword') }}</ion-label>
          <ion-input
            key="otp"
            type="text"
            class="text-3xl"
            min="6"
            max="6"
            :placeholder="$t('onetimepassword')"
            @ionInput="otp = $event.target.value" />
        </ion-item>
        <ion-item v-if="user.has_tfa">
          <ion-label position="stacked">{{ $t('authenticatorcode') }}</ion-label>
          <ion-input
            key="authenticatorcode"
            type="text"
            class="text-3xl"
            min="6"
            max="6"
            :placeholder="$t('authenticatorcode')"
            @ionInput="tfa_code = $event.target.value" />
        </ion-item>
        <ion-item>
          <LoadingButton :loading="loading" :disabled="loading" color="moto" @click="confirmEmailChange">
            {{ $t('submit') }}
          </LoadingButton>
        </ion-item>

      </template>
      <div class="mx-2">
        <Error :error="error" />
      </div>
    </ion-content>

    <ion-footer>
      <ion-button expand="full" @click="$ionic.modalController.dismiss()">
        <ion-label>{{ $t('cancel') }}</ion-label>
      </ion-button>
    </ion-footer>
  </base-modal>
</template>

<script>
  import BaseModal from './BaseModal.vue';
  import {
    CONFIRM_EMAIL_CHANGE_ACTION, FETCH_PENDING_EMAIL_CHANGES_ACTION,
    GET_USER_BY_TOKEN_ACTION,
    REQUEST_EMAIL_CHANGE_ACTION
  } from "@/store/store-actions";
  import { showToast, TOAST_SUCCESS } from "@/utils/helpers";
  import { mapGetters } from "vuex";
  import { CURRENT_USER_GETTER } from "@/store/store-getters";

  export default {
    props: ['email', 'confirmStep'],
    components: {
      BaseModal,
    },
    data() {
      return {
        loading: false,
        newEmail: this.email,
        onConfirmStep: !!this.confirmStep,
        error: null,
        otp: null,
        tfa_code: null,
      }
    },
    computed: {
      ...mapGetters({user: CURRENT_USER_GETTER}),
    },
    methods: {
      showToast,
      async requestEmailChange() {
        this.error = null;
        this.loading = true;
        try {
          await this.$store.dispatch(REQUEST_EMAIL_CHANGE_ACTION, {email: this.newEmail});
        } catch (e) {
          this.error = e;
          return;
        } finally {
          this.loading = false;
        }
        await this.showToast(this.$t('verificationemailsent'), TOAST_SUCCESS, 6000);
        await this.$store.dispatch(FETCH_PENDING_EMAIL_CHANGES_ACTION);

        this.onConfirmStep = true;
      },
      async confirmEmailChange() {
        this.error = null;
        this.loading = true;
        try {
          await this.$store.dispatch(CONFIRM_EMAIL_CHANGE_ACTION, {email: this.newEmail, otp: this.otp, tfa_code: this.tfa_code});
        } catch (e) {
          this.error = e;
          return;
        } finally {
          this.loading = false;
        }
        await this.showToast(this.$t('emailchangeconfirmed'), TOAST_SUCCESS, 6000);
        await this.$store.dispatch(GET_USER_BY_TOKEN_ACTION, {fresh: true});

        await this.$store.dispatch(FETCH_PENDING_EMAIL_CHANGES_ACTION);
        await this.$ionic.modalController.dismiss();
      }

    }
  }
</script>