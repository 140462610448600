<template>
  <ion-list>
    <ion-list-header>
      <slot name="header" />
    </ion-list-header>

    <div v-for="(form_field, key) in presentation" :key="key">
      <div>
        <div v-for="(field, fieldKey) in form_field.fields" :key="fieldKey">
          <Gender v-if="field.type == 'gender'" :field="field" :model="model" @inputChanged="inputChanged($event.target.value, key)" />

          <InputText v-if="field.type == 'text'" :field="field" :disabled="field.userKey == 'email' && true" :model="model" @inputChanged="inputChanged($event.target.value, key)" />

          <Date v-if="field.type == 'date'" :field="field" :model="model" @inputChanged="dateChanged($event.target.value, key)" />

          <Nationalities v-if="field.type == 'nationalities'" :field="field" :model="model" @inputChanged="inputChanged($event.target.value, key)" />

          <Bike v-if="field.type == 'bike'" :field="field" :model="model" @inputChanged="inputChanged($event, key)" />

          <MultiSelect
            v-if="field.type == 'multi-select'"
            :field="field"
            :model="model"
            @inputChanged="inputChanged($event, key)"
          />

          <Toggle v-if="field.type == 'toggle'" :field="field" :model="model" @inputChanged="inputChanged($event, key)" />

          <Doctor v-if="field.type == 'doctor'" :field="field" :model="model" @nameChanged="nameChanged($event.target.value, key)" @phoneChanged="phoneChanged($event.target.value, key)" />

          <Emergency v-if="field.type == 'emergency'" :field="field" :model="model" @nameChanged="nameChanged($event.target.value, key)" @phoneChanged="phoneChanged($event.target.value, key)" />

          <BloodTypes v-if="field.type == 'bloodtype'" :field="field" :model="model" @inputChanged="inputChanged($event.target.value, key)" />

          <TextArea v-if="field.type == 'textarea'" :field="field" :model="model" @inputChanged="inputChanged($event.target.value, key)" />
        </div>
      </div>
    </div>
  </ion-list>
</template>

<script>
  import Gender from "@/components/inputs/Gender";
  import InputText from "@/components/inputs/InputText";
  import Date from "@/components/inputs/Date";
  import Nationalities from "@/components/inputs/Nationalities";
  import Bike from "@/components/inputs/Bike";
  import MultiSelect from "@/components/inputs/MultiSelect";
  import Toggle from "@/components/inputs/Toggle";
  import Doctor from "@/components/inputs/Doctor";
  import Emergency from "@/components/inputs/Emergency";
  import BloodTypes from "@/components/inputs/BloodTypes";
  import TextArea from "@/components/inputs/TextArea";

  export default {
    components: {Gender, InputText, Date, Nationalities, Bike, MultiSelect, Toggle, Doctor, Emergency, BloodTypes, TextArea},
    props: [
      'model',
      'presentation',
    ],
    emits: [
      'inputChanged',
      'dateChanged',
      'nameChanged',
      'phoneChanged',
    ],
    methods: {
      inputChanged(value, key) {
        this.$emit('inputChanged', {value, key})
      },
      dateChanged(value, key) {
        this.$emit('dateChanged', {value, key})
      },
      nameChanged(value, key) {
        this.$emit('nameChanged', {value, key})
      },
      phoneChanged(value, key) {
        this.$emit('phoneChanged', {value, key})
      }
    }
  }
</script>
